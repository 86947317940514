import constants from 'appConstants';
import { isEmpty, isString } from 'lodash-es';
import {
    ON_RECEIVE_NEW_ROBOT_STATUS,
    ON_RECEIVE_NEW_ROBOT_VERSIONS,
    ON_GET_SYSTEM_STATUSES,
    SET_CURRENT_BHOME,
    ON_CREATE_SSH_TUNNEL,
    SET_LIVE_VIDEO_URL,
    ON_RECEIVE_CAMERA_DEVICES,
    ON_RECEIVE_DEVICES,
    ON_SET_LAYOUT,
    ON_GET_IR_TEMPERATURE,
    ON_GET_POWER_DATA,
    ON_FRAME_SCALE_CALIBRATE_INFO,
    ON_FRAME_SCALE_WEIGHT,
} from 'actionTypes';
import {
    FETCH_ACTIVITIES,
    FETCH_OPEN_SSH_TUNNEL,
    FETCH_SEND_TECHNICIAN_ACTION,
    FETCH_FRAMES,
    SET_SYSTEM_STATUSES,
    FETCH_SET_LIGHTING_SETTINGS,
    CLEAR_ROBOT_VERSIONS,
    CLEAR_DEVICES,
    TOGGLE_LIVE_STREAM,
    FETCH_SOFTWARE_BUNDLES,
    FETCH_AUTO_FEATURES_STATE,
} from '../actionTypes';
import { FETCH_CHANGE_MARKED_HIVES } from '../../../reusables/BhomeLayout/actionTypes';

const DEFAULT_STRING = 'Not Fetched';
const FETCHING_STRING = 'Fetching...';

const initialState = {
    robotVersions: {
        '-': '-',
    },
    status: {
        state: DEFAULT_STRING,
    },
    activities: {
        inProgress: [],
        done: [],
        isFetched: false,
    },
    logsToDownload: '',
    logs: '',
    logsText: '',
    logsError: null,
    logsStopTime: null,
    devices: [],
    systemStatuses: {},
    isSshFetched: false,
    ssh: {
        port: null,
        id: null,
    },
    frames: [],
    stations: {},
    markedHives: [],
    cameraDevices: [],
    frameLoaderVideo: {
        url: null,
        camera: 'techCam',
    },
    hallwayVideo: {
        url: null,
    },
    liveVideos: [],
    ipCameraDevice: null,
    irTemperatures: {
        a: null,
        b: null,
    },
    powerData: null,
    softwareBundles: [],
    frameScale: {
        zero_offset: '',
        calibration_factor: '',
        initiated_by: '',
    },
    autoFeaturesState: {},
};

const actionsMonitoring = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ACTIVITIES.success:
            return {
                ...state,
                activities: {
                    inProgress: action.data.messages.inProgress.reverse(),
                    done: action.data.messages.done,
                    isFetched: true,
                },
            };
        case ON_RECEIVE_DEVICES:
            return {
                ...state,
                devices: action.payload.data ?? initialState.devices,
            };
        case CLEAR_DEVICES:
            return {
                ...state,
                devices: initialState.devices,
            };
        case ON_RECEIVE_NEW_ROBOT_STATUS:
            const parsedData = isString(action.payload.data) ? JSON.parse(action.payload.data) : action.payload.data;
            return {
                ...state,
                status: {
                    ...parsedData,
                    lcCalibration: action.payload.lcCalibration,
                },
                systemStatuses: {
                    serviceLight:
                        parsedData?.light1?.toLowerCase() === 'on' && parsedData?.light2?.toLowerCase() === 'on',
                    fanA: parsedData?.fan1?.toLowerCase() === 'on',
                    fanB: parsedData?.fan2?.toLowerCase() === 'on',
                },
            };
        case ON_RECEIVE_NEW_ROBOT_VERSIONS:
            return {
                ...state,
                robotVersions: action.payload.data,
            };
        case CLEAR_ROBOT_VERSIONS:
            return {
                ...state,
                robotVersions: initialState.robotVersions,
            };
        case ON_GET_SYSTEM_STATUSES:
            return {
                ...state,
                systemStatuses: {
                    ...state.systemStatuses,
                    ...action.payload,
                },
            };
        case SET_SYSTEM_STATUSES:
            return {
                ...state,
                systemStatuses: {
                    ...state.systemStatuses,
                    ...action.statuses,
                },
            };
        case FETCH_SET_LIGHTING_SETTINGS.success:
            return {
                ...state,
                systemStatuses: {
                    serviceLight: action.data.status ? 'on' : 'off',
                },
            };
        case ON_CREATE_SSH_TUNNEL:
            return {
                ...state,
                isSshFetched: true,
                ssh: action.payload,
            };
        case FETCH_OPEN_SSH_TUNNEL.success:
            return {
                ...state,
                isSshFetched: true,
                ssh: action.data,
            };
        case FETCH_SEND_TECHNICIAN_ACTION.success:
            let changedState = {};
            if (action.data.type === constants.COMMANDS.GET_ROBOT_STATUS) {
                changedState = {
                    status: {
                        state: FETCHING_STRING,
                    },
                };
            }

            if (!isEmpty(changedState)) {
                return {
                    ...state,
                    ...changedState,
                };
            }

            return state;
        case SET_CURRENT_BHOME:
            return {
                ...state,
                ...initialState,
            };
        case FETCH_FRAMES.success:
        case ON_SET_LAYOUT:
            return {
                ...state,
                frames: action?.data?.frames,
                stations: action?.data?.stations,
                markedHives: action?.data?.markedHives,
                layoutTimestamp: action?.data?.layoutTimestamp,
            };
        case FETCH_CHANGE_MARKED_HIVES.success:
            return {
                ...state,
                markedHives: action?.data,
            };
        case FETCH_SOFTWARE_BUNDLES.success:
            return {
                ...state,
                softwareBundles: action?.data.result,
            };
        case ON_RECEIVE_CAMERA_DEVICES:
            return {
                ...state,
                cameraDevices: action?.data?.devices,
            };
        case TOGGLE_LIVE_STREAM.success:
        case SET_LIVE_VIDEO_URL: {
            const key = action.type === SET_LIVE_VIDEO_URL ? 'payload' : 'data';
            let liveVideos = [];
            if (state.liveVideos.some(video => video?.cameraNum === action?.[key]?.stream?.cameraNum)) {
                liveVideos = state.liveVideos.reduce((acc, video) => {
                    if (video.cameraNum === action?.[key]?.stream?.cameraNum) {
                        if (action?.[key]?.stream?.url) {
                            acc.push(action?.[key].stream);
                        }
                        return acc;
                    }
                    acc.push(video);
                    return acc;
                }, []);
            } else {
                if (action?.[key]?.stream?.url) {
                    liveVideos = [...state.liveVideos, action?.[key].stream];
                } else {
                    liveVideos = [...state.liveVideos];
                }
            }
            return {
                ...state,
                liveVideos,
            };
        }
        case ON_GET_IR_TEMPERATURE: {
            return {
                ...state,
                irTemperatures: {
                    ...state.irTemperatures,
                    [action?.payload?.data?.sensor_id]: action?.payload?.data?.temperature,
                },
            };
        }
        case ON_GET_POWER_DATA:
            return {
                ...state,
                powerData: action.payload.data,
            };
        case ON_FRAME_SCALE_CALIBRATE_INFO:
        case ON_FRAME_SCALE_WEIGHT:
            return {
                ...state,
                frameScale: {
                    ...state.frameScale,
                    ...action.payload.data,
                },
            };
        case FETCH_AUTO_FEATURES_STATE.success: {
            return {
                ...state,
                autoFeaturesState: action.data,
            };
        }
        default:
            return state;
    }
};

export default actionsMonitoring;
