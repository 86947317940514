import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { arrayOfObjectsShallowEqual, loading, useAsyncPoll, useToggle } from '@beewise/react-utils';
import { useIdleTimer } from 'react-idle-timer';
import constants from 'appConstants';
import Grid from 'components/reusables/Grid';
import { getBhomes } from 'components/reusables/AppHeader/selectors/bhome.ids.selector';
import Checkbox from '@beewise/checkbox-field';
import TextField from '@beewise/text-field';
import { SelectField } from '@beewise/select-field';
import { fetchAllBhomes, fetchAllViews, fetchCreateView, fetchDeleteView } from 'actions/app.actions';
import { useNavigate } from 'react-router-dom';
import { fetchBhomeConnectivityMap, fetchConnectivityMapData, fetchLatestBhomesErrors } from './actions';
import { getDataToDisplay, columnMonitoringDefs } from './utils';
import {
    FETCH_CONNECTIVITY_MAP_DATA,
    FETCH_BHOME_CONNECTIVITY_MAP,
    FETCH_LATEST_BHOMES_ERRORS,
    FETCH_UPDATE_BHOME_NOTE,
} from './actionTypes';
import { isLimitedBayUser } from '../../../utils';
import BulkSActions from './components/BulkActions';
import SaveViewModal from './components/SaveViewModal';
import Chip from './components/Chip';

const Monitoring = () => {
    const navigate = useNavigate();
    const [columnApi, setColumnApi] = useState(null);
    const [hiddenColumns, setHiddenColumns] = useState(
        JSON.parse(localStorage.getItem(constants.TECHNICIAN_CONNECTIVITY_MAP_COLUMNS)) || []
    );
    const [filters, setFilters] = useState(
        JSON.parse(localStorage.getItem(constants.TECHNICIAN_CONNECTIVITY_MAP_FILTERS)) || []
    );
    const [isOpen, toggleModal] = useToggle();

    const dispatch = useDispatch();
    const user = useSelector(state => state.app.user, shallowEqual);
    const connectivity = useSelector(state => state.monitoring.connectivity, arrayOfObjectsShallowEqual);
    const errors = useSelector(state => state.monitoring.errors, arrayOfObjectsShallowEqual);
    const data = useSelector(state => state.monitoring.data, shallowEqual);
    const bhomes = useSelector(getBhomes, arrayOfObjectsShallowEqual);
    const views = useSelector(state => state.app.views, arrayOfObjectsShallowEqual);
    const [selectedBhomeIds, setSelectedBhomeIds] = useState([]);
    const [selectedView, setSelectedView] = useState(null);
    const [filterModel, setFilterModel] = useState({});
    const [bhomeIds, setBhomeIds] = useState(null);

    const handleOnClose = useCallback(() => {
        toggleModal();
    }, [toggleModal]);

    useIdleTimer({
        onIdle: () => navigate('/'),
        timeout: 5 * 60 * 1000,
    });

    const isBayUser = useMemo(() => isLimitedBayUser(user), [user]);

    const handleInitialFetch = useCallback(() => {
        dispatch(fetchAllBhomes());
        dispatch(fetchBhomeConnectivityMap());
        dispatch(fetchLatestBhomesErrors());
        dispatch(fetchConnectivityMapData());
        dispatch(fetchAllViews());
    }, [dispatch]);

    useEffect(() => {
        handleInitialFetch();
    }, [handleInitialFetch]);

    useAsyncPoll(() => {
        handleInitialFetch();
    }, 60 * 1000);

    const handleUpdate = useCallback(() => {
        handleInitialFetch();
    }, [handleInitialFetch]);

    const handleSaveColumnsState = useCallback((columnApi, state) => {
        const stateToSave = state || columnApi.getColumnState();
        localStorage.setItem(constants.TECHNICIAN_CONNECTIVITY_MAP_COLUMNS_ORDER, JSON.stringify(stateToSave));
    }, []);

    const toggleFilter = useCallback(
        name => () => {
            const filter = filters.includes(name) ? filters.filter(item => item !== name) : [...filters, name];
            localStorage.setItem(constants.TECHNICIAN_CONNECTIVITY_MAP_FILTERS, JSON.stringify(filter));
            setFilters(filter);
        },
        [filters]
    );

    const dataToDisplay = useMemo(
        () =>
            getDataToDisplay({
                bhomes,
                errors,
                data,
                connectivity,
                filters,
                isBayUser,
                selectedBhomeIds,
            }),
        [bhomes, errors, data, connectivity, filters, isBayUser, selectedBhomeIds]
    );

    const dataToPercents = useMemo(
        () =>
            getDataToDisplay({
                bhomes,
                errors,
                data,
                connectivity,
                isBayUser,
            }),
        [bhomes, errors, data, connectivity, isBayUser]
    );

    const [onlinePercentage, setOnlinePercentage] = useState(0);
    const [offlinePercentage, setOfflinePercentage] = useState(0);

    useEffect(() => {
        if (dataToPercents.length > 0) {
            const totalBHCount = dataToPercents.length;
            const onlineBHCount = dataToPercents.filter(item => item.connected).length;
            const offlineBHCount = dataToPercents.filter(item => !item.connected).length;

            const calculatedOnlinePercentage = ((onlineBHCount / totalBHCount) * 100).toFixed(1);
            const calculatedOfflinePercentage = ((offlineBHCount / totalBHCount) * 100).toFixed(1);

            setOnlinePercentage(calculatedOnlinePercentage);
            setOfflinePercentage(calculatedOfflinePercentage);
        }
    }, [dataToPercents]);

    const setActiveView = useCallback(
        view => {
            if (selectedView?.id === view?._id) {
                setSelectedView(null);
                if (!columnApi) {
                    return;
                }
                columnApi.applyColumnState({
                    defaultState: { sort: null },
                });
            } else {
                const stateToApply = columnMonitoringDefs
                    .map(def => {
                        const viewColumnState = view.view.find(colState => colState.colId === def.field);

                        return viewColumnState
                            ? {
                                  ...def,
                                  sort: viewColumnState.sort,
                                  sortIndex: viewColumnState.sortIndex,
                              }
                            : null;
                    })
                    .filter(col => col !== null);

                setSelectedView({ id: view._id, data: stateToApply, filters: view?.filters });
            }
        },
        [columnApi, selectedView?.id]
    );

    const getRowId = useCallback(({ data }) => data.id, []);

    const handleHiddenColumnsChange = useCallback(
        value => {
            localStorage.setItem(constants.TECHNICIAN_CONNECTIVITY_MAP_COLUMNS, JSON.stringify(value));
            setHiddenColumns(value);

            const columnState = columnApi.getColumnState().map(col => {
                if (value.includes(col.colId)) {
                    return {
                        ...col,
                        hide: true,
                    };
                }
                return {
                    ...col,
                    hide: false,
                };
            });

            columnApi.applyColumnState({
                state: columnState,
                applyOrder: true,
            });
            handleSaveColumnsState(columnApi, columnState);
        },
        [columnApi, handleSaveColumnsState]
    );

    const hiddenOptions = useMemo(
        () =>
            columnMonitoringDefs.map(item => ({
                label: item.headerName,
                value: item.field,
            })),
        []
    );

    const onColumnMoved = useCallback(
        ({ columnApi }) => {
            handleSaveColumnsState(columnApi);
        },
        [handleSaveColumnsState]
    );

    const handleOnGridReady = useCallback(
        params => {
            setColumnApi(params.columnApi);
            const columnState = JSON.parse(localStorage.getItem(constants.TECHNICIAN_CONNECTIVITY_MAP_COLUMNS_ORDER));
            if (columnState && columnState.length === columnMonitoringDefs.length) {
                params.columnApi.applyColumnState({
                    state: columnState,
                    applyOrder: true,
                });
            } else {
                handleSaveColumnsState(params.columnApi);
            }
            const filterModel = JSON.parse(localStorage.getItem(constants.TECHNICIAN_CONNECTIVITY_MAP_GRID_FILTERS));
            if (filterModel) {
                params.api.setFilterModel(filterModel);
                setFilterModel(filterModel);
            }
        },
        [handleSaveColumnsState]
    );

    const onFilterChanged = useCallback(({ api }) => {
        setFilterModel(api.getFilterModel());
        localStorage.setItem(constants.TECHNICIAN_CONNECTIVITY_MAP_GRID_FILTERS, JSON.stringify(api.getFilterModel()));
    }, []);

    const onSelect = useCallback(
        ({ api }) => {
            const selected = api?.getSelectedRows();
            const bhomeIds = selected?.map(({ id }) => id);

            if (selectedBhomeIds.length === bhomeIds.length) {
                return;
            }
            setSelectedBhomeIds(bhomeIds);
        },
        [selectedBhomeIds?.length]
    );

    const handleSaveView = useCallback(
        newView => {
            if (!columnApi || !newView) return;

            const columnState = columnApi.getColumnState();
            const visibleColumns = columnState.filter(colState => colState.hide !== true);
            dispatch(
                fetchCreateView({
                    body: {
                        viewData: visibleColumns,
                        filters: filterModel,
                        title: newView,
                    },
                    resolver: data => setActiveView(data?.result),
                })
            );
            handleOnClose();
        },
        [columnApi, dispatch, filterModel, handleOnClose, setActiveView]
    );

    const handleDeleteView = viewId => {
        dispatch(fetchDeleteView(viewId));
    };

    const onRowDataChange = ({ gridApi }) => {
        if (!selectedView) {
            return;
        }
        gridApi.setFilterModel(selectedView?.filters);
    };

    const validateAndSetBhomeIds = useCallback(
        enteredIds => {
            setBhomeIds(enteredIds);
            const idsArray = enteredIds.split(' ').map(Number);
            const validIds = idsArray.filter(id => bhomes.some(bhome => bhome.id === id));
            setSelectedBhomeIds(validIds);
        },
        [bhomes]
    );

    const memoizedColumnDefs = useMemo(() => selectedView?.data || columnMonitoringDefs, [selectedView?.data]);

    return (
        <div className="connectivity-map-view">
            {views.length !== 0 && (
                <>
                    <div className="connectivity-map-view-title">Views</div>
                    {views.map(view => (
                        <Chip
                            key={view?._id}
                            title={view.title}
                            handleDeleteView={() => handleDeleteView(view._id)}
                            isActiveView={selectedView?.id === view._id}
                            setActiveView={() => setActiveView(view)}
                        />
                    ))}
                </>
            )}
            <div className="connectivity-map-view-checkboxes">
                <SelectField
                    value={hiddenColumns}
                    options={hiddenOptions}
                    onChange={handleHiddenColumnsChange}
                    placeholder="Hide Columns"
                    isMulti
                    clearable
                    search
                    className="beewise-select"
                    size="small"
                />
                <div className="connectivity-map-view-checkboxes-wrapper">
                    <Checkbox
                        label="Show online"
                        checked={filters.some(item => item === 'onlineShown')}
                        disabled={filters.some(item => item === 'offlineShown')}
                        onChange={toggleFilter('onlineShown')}
                    />
                    <Checkbox
                        label="Show offline"
                        checked={filters.some(item => item === 'offlineShown')}
                        disabled={filters.some(item => item === 'onlineShown')}
                        onChange={toggleFilter('offlineShown')}
                    />
                    <Checkbox
                        label="Show receptive"
                        checked={filters.some(item => item === 'receptiveShown')}
                        onChange={toggleFilter('receptiveShown')}
                    />
                    <Checkbox
                        label="Show non-receptive"
                        checked={filters.some(item => item === 'nonReceptive')}
                        onChange={toggleFilter('nonReceptive')}
                    />
                    <Checkbox
                        label="Show non-existing IoT device"
                        checked={filters.some(item => item === 'nonExisting')}
                        onChange={toggleFilter('nonExisting')}
                    />
                    <Checkbox
                        label="Open Door"
                        checked={filters.some(item => item === 'doorOpen')}
                        onChange={toggleFilter('doorOpen')}
                    />
                    <Checkbox
                        label="Needs Vision Calibration"
                        checked={filters.some(item => item === 'needCalibration')}
                        onChange={toggleFilter('needCalibration')}
                    />
                    <Checkbox
                        label="Needs LC Calibration"
                        checked={filters.some(item => item === 'needLcCalibration')}
                        onChange={toggleFilter('needLcCalibration')}
                    />
                    <Checkbox
                        label="Show marked only"
                        checked={filters.some(item => item === 'markedOnly')}
                        onChange={toggleFilter('markedOnly')}
                    />
                </div>
            </div>
            <div className="percent-block">
                <p>
                    Total : The current numbers of overall BH’s for the specific state{' '}
                    <span className="percent-bold">100%</span>
                </p>
                <p>
                    Summary of online BH’s : <span className="percent-bold">{onlinePercentage}%</span>
                </p>
                <p>
                    Summary of offline BH’s : <span className="percent-bold">{offlinePercentage}%</span>
                </p>
            </div>
            <Grid
                rowData={dataToDisplay}
                getRowId={getRowId}
                columnDefs={memoizedColumnDefs}
                onGridReady={handleOnGridReady}
                onSelect={onSelect}
                handleUpdate={handleUpdate}
                isAbsoluteHeader
                onColumnMoved={onColumnMoved}
                onFilterChanged={onFilterChanged}
                hideSearch
                selectedBhomeIds={selectedBhomeIds}
                onRowDataChange={onRowDataChange}
                actionChildren={
                    <>
                        <BulkSActions selectedBhomeIds={selectedBhomeIds} setSelectedBhomeIds={setSelectedBhomeIds} />
                        <TextField
                            label="Enter Beehome IDs"
                            value={bhomeIds}
                            onChange={validateAndSetBhomeIds}
                            size="small"
                        />
                    </>
                }
                handleOnClose={handleOnClose}
            />
            <SaveViewModal isOpen={isOpen} handleOnClose={handleOnClose} handleSaveView={handleSaveView} />
        </div>
    );
};

export default loading([
    FETCH_CONNECTIVITY_MAP_DATA.default,
    FETCH_BHOME_CONNECTIVITY_MAP.default,
    FETCH_LATEST_BHOMES_ERRORS.default,
    FETCH_UPDATE_BHOME_NOTE.default,
])(Monitoring);
